@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
   font-family: 'Merriweather', Arial, Helvetica, sans-serif;
   font-size: 32px;
   font-weight: bold;
   letter-spacing: 0.3px;
}

p, li {
   font-family: 'EB Garamond', Arial, Helvetica, sans-serif;
   font-size: 18px;
   letter-spacing: 0.5px;
}

button {
   font-family: 'Lato', Arial, Helvetica, sans-serif;
   font-size: 18px;
}

span {
   font-weight: 600;
}

body {
   background-color: #f5f5f5;
}

::-webkit-scrollbar {
   display: none;
}

.card {
   box-shadow: 2px 2px 15px -5px rgba(0,0,0,0.75);
-webkit-box-shadow: 2px 2px 15px -5px rgba(0,0,0,0.75);
-moz-box-shadow: 2px 2px 15px -5px rgba(0,0,0,0.75);
}

@media only screen and (max-width: 1536px) {
   h1 {
      font-size: 28px;
   }

   span {
      font-weight: 500;
   }
}

@media only screen and (max-width: 1280px) {
   h1 {
      font-size: 26px;
   }

   p, li, button {
      font-size: 17px;
   }
}

@media only screen and (max-width: 1024px) {
   h1 {
      font-size: 18px;
   }

   p, button, li {
      font-size: 14px;
   }
}

@media only screen and (max-width: 768px) {
   h1 {
      font-size: 20px;
   }
}